<template>
  <gl-modal
    v-bind="$attrs"
    full-buttons
    submit-title="Apply"
    title="Rename Case"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <div class="mb-4">
      Enter the new case name in the field below
    </div>
    <gl-input
      v-model="title"
      class="gl-modal__row"
      label="New title"
      name="value"
      :rules="rules"
    />
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'
import GlInput from '@/components/gl-input'

export default {
  components: {
    GlModal,
    GlInput,
  },
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    rules() {
      return `required`
    },
  },
  methods: {
    onSubmit() {
      this.$emit('update', this.title)
    },
  },
}
</script>
