<template>
  <div class="page-wrapper relative">
    <div class="flex align-end space-between labeling-tabs-wrap">
      <div class="flex mb-4 m-fullwidth">
        <div
          class="tab m-flex-1 mr-4 m-mr-0"
          :class="{'tab-active': activeTab === 'active-cases'}"
        >
          <h1
            class="page-title-tab"
            
            @click="handleActiveTab('active-cases')"
          >
            Active cases
          </h1>
        </div>
        <div
          class="tab m-flex-1 mr-4 m-mr-0"
          :class="{'tab-active': activeTab === 'archive'}"
        >
          <h1
            class="page-title-tab"
            
            @click="handleActiveTab('archive')"
          >
            Archive
          </h1>
        </div>
      </div>
    </div>
    <ActiveCases
      v-if="activeTab === 'active-cases'"
      :active-tab="activeTab"
    />
    <ArchiveCases
      v-if="activeTab === 'archive'"
      :active-tab="activeTab"
    />
  </div>
</template>

<script>
// Components
import ActiveCases from './components/ActiveCases'
import ArchiveCases from './components/ArchiveCases'

export default {
  components: {
    ActiveCases,
    ArchiveCases,
  },
  data() {
    return {
      search: '',
      activeTab: 'active-cases',
    }
  },
  mounted() {
    const tab = this.$route.query.tab || this.activeTab

    if (tab) {
      this.activeTab = tab
      this.$router.replace({ name: 'cases', query: { tab: this.activeTab } }).catch(err => err)
    }
  },
  methods: {
    handleActiveTab(name) {
      this.activeTab = name
      this.$router.replace({ name: 'cases', query: { tab: this.activeTab } }).catch(err => err)
    },
  },
}
</script>

<style scoped>
.page-title-tab {
  font-size: 20px;
}

.labeling-tabs-wrap {
  white-space: nowrap;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.tab {
  position: relative;
}

.tab-counter {
  height: 20px;
  min-width: 20px;
  border-radius: 20px;
  background: var(--scarlet-red);
  color: #fff;
  position: absolute;
  top: -15px;
  right: -15px;
  font-size: 14px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 1px 4px;
}

@media (max-width: 767px) {
 .page-title-tab {
    font-size: 14px;
  }
}
</style>
