<template>
  <div class="container">
    <div class="pa-3 flex align-end flex-wrap space-between">
      <div class="flex align-end flex-wrap">
        <div class="mr-2 pa-1 m-fullwidth m-mr-0">
          <div class="tagging__key mb-2">
            Search by case name
          </div>
          <gl-search-box
            v-model="search"
            button-text="Search"
            class="mr-2"
            dark-clear
            :disabled="!search"
            grey
            :min-width="isMobile ? '150px' : '250px'"
            placeholder="Enter the case name"
            @clear="clearSearching"
            @search="loadData"
          />
        </div>
        <div class="flex mr-3 pa-1 m-fullwidth m-mr-0 m-mt-2">
          <div class="flex-1">
            <div class="tagging__key mb-2">
              filter by date & time
            </div>
            <date-picker
              ref="datePicker"
              v-model="transactionsListDates"
              class="cases-date-picker"
              format="YYYY-MM-DD HH:mm:ss"
              placeholder="Select datetime range"
              range
              :show-time-panel="showTimeRangePanel"
              type="datetime"
              @clear="handleDateClose"
              @close="handleRangeClose"
            >
              <template v-slot:footer>
                <div class="flex space-between">
                  <div>
                    <button
                      class="mx-btn mx-btn-text link"
                      @click="toggleTimeRangePanel"
                    >
                      {{ showTimeRangePanel ? 'Select date' : 'Select time' }}
                    </button>
                  </div>
                  <div>
                    <button
                      class="mx-btn mx-btn-text cancel-date-range mr-2"
                      @click="handleDateClose"
                    >
                      Cancel
                    </button>
                    <button
                      class="mx-btn mx-btn-text apply-date-range"
                      @click="setDate(transactionsListDates)"
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </template>
            </date-picker>
          </div>
          <gl-menu-item
            class="m-flex align-end mb-1 ml-2 display-none"
            icon="delete-action"
            :icon-height="24"
            :icon-width="24"
            label="Clear all"
            @click="clearDate"
          />
        </div>
        <GlCoinSelect
          class="pa-1 m-mt-2"
          dark
          label="Filter by coin"
          small
          @change="loadData"
        />
      </div>
      <div class="flex align-end flex-wrap m-mt-2">
        <div
          v-if="selectedIds.length > 0"
          class="flex align-center mr-2 mb-1 pa-1"
          @click="showRemoveSelectedModal = true"
        >
          <gl-icon
            class="mr-1"
            :height="24"
            name="delete"
            :width="24"
          />
          <div class="warning-text pointer">
            Delete selected
          </div>
        </div>
        <div
          v-if="selectedIds.length > 0"
          class="flex align-center mb-1 pa-1 mr-2"
          @click="reopenSelectedCase"
        >
          <gl-icon
            class="mr-1"
            :height="24"
            name="refresh"
            :width="24"
          />
          <div class="pointer">
            Reopen selected
          </div>
        </div>
        <div
          v-if="selectedIds.length === 0"
          class="flex align-center mb-1 pa-1"
          @click="selectAll"
        >
          <gl-icon
            class="mr-1"
            :height="24"
            name="select-all"
            :width="24"
          />
          <div class="pointer">
            Select all
          </div>
        </div>
        <div
          v-else
          class="flex align-center mb-1 pa-1 mr-2"
          @click="deselectAll"
        >
          <gl-icon
            class="mr-1"
            :height="24"
            name="select-all"
            :width="24"
          />
          <div class="pointer">
            Unselect all
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="loading"
      class="flex align-center justify-center modal-loader"
      style="z-index: 10"
    >
      <gl-loader />
    </div>
    <div class="grid-row">
      <div
        v-for="(item, index) in cases"
        :key="index"
        class="grid-item pointer"
        @click="updateSelected(item)"
      >
        <div
          class="grid-item-wrapper"
          :class="{ 'grid-item__selected': isSelected(item._id) }"
        >
          <div class="grid-item-container">
            <div class="pa-3">
              <div class="bold flex space-between mb-4">
                <div class="flex">
                  <div
                    class="ellipsis"
                    style="max-width: 200px; min-height: 28px; padding-top: 5px;"
                  >
                    {{ item.title }}
                  </div>
                  <gl-icon
                    v-if="!hasSelected"
                    class="ml-3"
                    :height="24"
                    name="edit-block-active"
                    :width="24"
                    @click.stop="openEditModal(item)"
                  />
                </div>
                <div
                  v-click-outside="onClickOutside"
                  class="relative"
                >
                  <gl-checkbox
                    v-if="hasSelected"
                    v-model="item.selected"
                    class="selected-case-checkbox"
                  />
                  <div
                    v-if="!hasSelected"
                    class="cases-context-menu-activator"
                  >
                    <gl-icon
                      :height="24"
                      name="more"
                      :width="24"
                      @click.stop="openContextMenu(index)"
                    />
                  </div>
                  <div
                    v-if="showContextMenu && index === contextMenuIndex"
                    ref="casesMenu"
                    class="cases-context-menu"
                  >
                    <div
                      class="flex align-center mb-1"
                      @click.stop="reopenCase(item)"
                    >
                      <gl-icon
                        class="mr-2"
                        :height="24"
                        name="refresh"
                        :width="24"
                      />
                      <div>
                        Reopen
                      </div>
                    </div>
                    <div
                      class="flex align-center"
                      @click.stop="openRemoveModal(item)"
                    >
                      <gl-icon
                        class="mr-2"
                        :height="24"
                        name="delete"
                        :width="24"
                      />
                      <div class="warning-text">
                        Delete
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="flex justify-center mb-4 case-img-wrap"
                :style="`height: ${300}px; backgroundImage: url('data:image/png;base64, ${item.image}')`"
              />
              <div class="flex space-between mb-4 m-gap-3 m-flex-wrap">
                <InfoBlock
                  class="stat-item flex-1"
                  label="Started"
                  :value="item.createdAt ? formatDate(item.createdAt, 'dd.MM.yyyy') : null"
                />
                <InfoBlock
                  class="stat-item flex-1"
                  label="Last opened"
                  :value="item.lastOpened ? formatDate(item.lastOpened, 'dd.MM.yyyy') : null"
                />
                <InfoBlock
                  v-if="item.blockchain"
                  class="stat-item flex-1"
                  label="Blockchain"
                  :value="item.blockchain"
                />
              </div>
              <InfoBlock
                class="stat-item flex-1 word-break mb-1"
                label="Starting point"
                :value="item.search"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex space-between pa-1 m-mr-2">
      <div class="flex align-center m-column pa-3 m-pa-2 m-pt-2">
        <div class="mr-2 fs-14 m-fs-12 bold m-mb-3">
          Rows per page:
        </div>
        <vSelect
          v-model="perPage"
          class="select__pagination mr-1"
          :clearable="false"
          :options="pagesOptions"
          @input="countChange"
        />
      </div>
      <o-pagination
        v-if="cases.length && totalPages > 1"
        class="stat-pagination"
        :current.sync="currentPage"
        order="right"
        :per-page="perPage"
        simple
        :total="total"
        @change="pageChange"
      >
        <o-pagination-button
          slot="previous"
          slot-scope="props"
          :page="props.page"
        >
          <gl-menu-item
            class="change-page mr-2"
            :disabled="props.page.disabled"
            icon="left"
            :icon-height="24"
            :icon-width="24"
            label=""
            not-outline
          />
        </o-pagination-button>

        <o-pagination-button
          slot="next"
          slot-scope="props"
          :page="props.page"
        >
          <gl-menu-item
            class="change-page"
            :disabled="props.page.disabled"
            icon="right"
            :icon-height="24"
            :icon-width="24"
            label=""
            not-outline
          />
        </o-pagination-button>
      </o-pagination>
    </div>
    <RemoveCaseModal
      :id="removingCase._id"
      v-model="showRemoveModal"
      :title="removingCase.title"
      @close="showRemoveModal = false"
      @remove="removeCase"
    />
    <RenameCaseModal
      v-model="showRenameModal"
      :title="updatingCase.title"
      @close="showRenameModal = false"
      @update="updateCase"
    />
    <RemoveSelectedCasesModal
      v-model="showRemoveSelectedModal"
      @close="showRemoveSelectedModal = false"
      @remove="removeCase(selectedIds.join(','))"
    />
  </div>
</template>

<script>
//Component
import GlIcon from '@/components/gl-icon';
import GlSearchBox from '@/components/gl-search-box'
import GlMenuItem from '@/components/gl-menu-item'
import vSelect from 'vue-select'
import GlLoader from '@/components/gl-loader'
import GlCheckbox from '@/components/gl-checkbox'
import RenameCaseModal from '../modals/RenameCaseModal'
import RemoveSelectedCasesModal from "@/pages/cases/modals/RemoveSelectedCasesModal";
import InfoBlock from '../../../components/gl-info-block'
// Utils
import { formatDate } from "@/utils/format-date";
import vClickOutside from 'v-click-outside'
// Vuex
import { mapMutations, mapActions, mapState } from 'vuex'
import {validate} from "vee-validate";
import RemoveCaseModal from "@/pages/cases/modals/RemoveCaseModal";
import moment from "moment";
import GlCoinSelect from "@/components/gl-coin-select.vue";
// Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  name: 'ActiveCases',
  directives: {
    clickOutside: vClickOutside.directive
  },
  components:{
    GlCoinSelect,
    vSelect,
    GlLoader,
    GlMenuItem,
    RemoveCaseModal,
    GlSearchBox,
    GlCheckbox,
    GlIcon,
    RenameCaseModal,
    RemoveSelectedCasesModal,
    InfoBlock
  },
  mixins: [deviceWidthMixin],
  props: {
    activeTab: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showContextMenu: false,
      showRemoveModal: false,
      showRemoveSelectedModal: false,
      showRenameModal: false,
      removingCase: {},
      updatingCase: {},
      loading: false,
      cases: [],
      pagesOptions: [6, 12, 24, 48, 96],
      currentPage: 1,
      perPage: 6,
      totalPages: 1,
      total: 1,
      selectedIds: [],
      search: '',
      transactionsListDates: [],
      showTimePanel: false,
      showTimeRangePanel: false,
      from: null,
      to: null,
    }
  },
  computed: {
    ...mapState('analytics', ['coinData']),
    hasSelected() {
      return this.selectedIds.length > 0
    },
  },
  watch: {
    activeTab: {
      handler(tab) {
        if (tab === 'archive') {
          this.loadData()
        }
      },
      immediate: true
    }
  },
  mounted() {
    const { from, to } = this.$route.query

    if (from && to) {
      this.from = from
      this.to = to
      this.transactionsListDates = [new Date(String(moment.unix(from / 1000).format('YYYY-MM-DD HH:mm:ss'))), new Date(String(moment.unix(to / 1000).format('YYYY-MM-DD')))]
    }

    this.loadData()
  },
  methods: {
    moment,
    ...mapMutations({
      SET_GRAPH_FROM_CASE: 'cases/SET_GRAPH_FROM_CASE',
    }),
    ...mapActions('cases', ['editCase', 'deleteCase', 'casesToArchive', 'createCase', 'getArchiveCases']),
    onClickOutside() {
      this.showContextMenu = false
    },
    openCopyCase(copiedCase) {
      this.createCase({
        blockchain: copiedCase.blockchain,
        search: copiedCase.search,
        graph: copiedCase.graph,
        title: `Copy of ${copiedCase.title}`,
        image: copiedCase.image
      }).then(({ success, data }) => {
        if (success) {
          this.$toasted.global.success({ message: `Case copied successfully` })
          this.openCase(data)
        } else {
          this.$toasted.global.error({ message: data.message })
        }
      })
    },
    deselectAll() {
      this.selectedIds = []
      this.cases.forEach(item => item.selected = false)
    },
    selectAll() {
      this.selectedIds = this.cases.map(item => item._id)
      this.cases.forEach(item => item.selected = true)
    },
    handleDateClose() {
      this.transactionsListDates = []
      this.from = undefined;
      this.to = undefined;
      this.$refs.datePicker.closePopup()
      this.loadData();
    },
    loadData() {
      this.loading = true

      const searchingData = {
        from: this.from,
        to: this.to,
        title: this.search,
        count: this.perPage,
        skip: (this.currentPage - 1) * this.perPage,
      }

      this.getArchiveCases({
        from: searchingData && searchingData.from ? searchingData.from : undefined,
        to: searchingData && searchingData.to? searchingData.to : undefined,
        title: searchingData && searchingData.title ? searchingData.title : undefined,
        count: searchingData && searchingData.count ? searchingData.count : 6,
        skip: searchingData && searchingData.skip ? searchingData.skip : 0,
        coin: this.coinData.key.toUpperCase()
      })
        .then(({ data: { items, totalItems } }) => {
          this.total = totalItems
          this.totalPages = Math.ceil(this.total / this.perPage)
          items.forEach(item => item.selected = false)
          this.cases = items.filter(item => item.archived)
          this.loading = false
        })

      this.$nextTick(() => {
        this.selectedIds = []
      })

      this.$router.replace({ name: 'cases', query: { tab: 'archive', from: this.from, to: this.to } })
    },
    countChange() {
      this.loadData()
    },
    pageChange(event) {
      this.currentPage = event
      this.loadData()
    },
    clearSearching() {
      this.search = ''
      this.loadData()
    },
    setDate(date) {
      const [from, to] = date

      if (from && to) {
        this.from = this.toSeconds(from) * 1000;
        this.to = this.toSeconds(to) * 1000;
        this.$refs.datePicker.closePopup()
        this.loadData();
      } else this.clearDate()
    },
    clearDate() {
      this.transactionsListDates = []
      this.from = undefined;
      this.to = undefined;
      this.loadData();
    },
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel;
    },
    toSeconds(date, formatDate = 'YYYY-MM-DD HH:mm') {
      return moment(date, formatDate).unix()
    },
    handleRangeClose() {
      this.showTimeRangePanel = false;
    },
    removeCase(id) {
      this.deleteCase({ caseIds: id }).then(({ success }) => {
        if (success) {
          this.loadData()
          this.showContextMenu = false
          this.selectedIds = []
        }
      })
    },
    updateCase(title) {
      this.editCase({ id: this.updatingCase._id, title }).then(({ success }) => {
        if (success) {
          this.loadData()
          this.showContextMenu = false
          this.showRenameModal = false
        }
      })
    },
    openRemoveModal(item) {
      this.removingCase = item
      this.showRemoveModal = true
    },
    updateSelected({ _id }) {
      this.showContextMenu = false

      const caseItem = this.cases.find(item => item._id === _id)

      if (this.isSelected(_id)) {
        const index = this.selectedIds.findIndex(id => id === _id)
        this.selectedIds.splice(index, 1)
        caseItem.selected = false
      } else {
        this.selectedIds.push(_id)
        caseItem.selected = true
      }
    },
    reopenSelectedCase(){
      const ids = this.selectedIds.join(',')

      this.casesToArchive({ ids, status: false }).then(({ success }) => {
        if (success) {
          this.loadData()
          this.showContextMenu = false
          this.selectedIds = []
        }
      })
    },

    reopenCase({ _id }){
      this.casesToArchive({ ids: _id, status: false }).then(({ success }) => {
        if (success) {
          this.loadData()
          this.showContextMenu = false
          this.selectedIds = []
        }
      })
    },
    isSelected(_id) {
      return this.selectedIds.find(id => String(id) === String(_id))
    },
    openEditModal(item) {
      this.updatingCase = item
      this.showRenameModal = true
    },
    openContextMenu(index) {
      this.showContextMenu = !this.showContextMenu
      this.contextMenuIndex = index
    },
    formatDate,
    async openCase(itemCase) {
      const hashValidationResult = await validate(itemCase.search, 'txHash', {name: 'Search value'})
      const addressValidationResult = await validate(itemCase.search, 'address:btc', { name: 'Search value' })

      if (hashValidationResult.valid) {
        itemCase = {
          ...itemCase,
          type: 'tx'
        }
      } else if (addressValidationResult.valid) {
        itemCase = {
          ...itemCase,
          type: 'address'
        }
      }

      this.SET_GRAPH_FROM_CASE(itemCase)
      this.$router.push({ name: 'analytics', query: { [itemCase.type]: itemCase.search, caseId: itemCase._id  }, params: { openCase: 'true', caseData: itemCase }})
    }
  }
}
</script>

<style>
.container {
  margin: 0 auto;
  background-color: var(--white);
  box-shadow: 0 0 8px 0 rgba(211, 211, 211, 0.64);
  border-radius: 4px;
}

.grid-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.grid-item {
  height: auto;
  flex-basis: 33%;
  -ms-flex: auto;
  width: 320px;
  position: relative;
  padding: 12px;
  box-sizing: border-box;
}

.grid-row a {
  text-decoration: none;
}

.wrapping-link {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  color: currentColor;
}

.grid-item-wrapper {
  -webkit-box-sizing: initial;
  -moz-box-sizing: initial;
  box-sizing: initial;
  background: #fff;
  border: 1px solid var(--dark-grey-d-3);
  border-radius: 3px;
  margin: 0;
  height: 100%;
  width: 100%;
  /*overflow: hidden;*/
  -webkit-transition: padding 0.15s cubic-bezier(0.4,0,0.2,1), margin 0.15s cubic-bezier(0.4,0,0.2,1), box-shadow 0.15s cubic-bezier(0.4,0,0.2,1);
  transition: padding 0.15s cubic-bezier(0.4,0,0.2,1), margin 0.15s cubic-bezier(0.4,0,0.2,1), box-shadow 0.15s cubic-bezier(0.4,0,0.2,1);
  position: relative;
}

.grid-item-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.grid-image-top {
  height: 45%;
  width: 120%;
  background-size: cover;
  position: relative;
  background-position: 50% 50%;
  left: -10.5%;
  top: -4.5%;
  display: none;
}

.grid-image-top .centered {
  text-align: center;
  transform: translate(-50%, -50%);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 54.5%;
  left: 50%;
  width: 60%;
  height: 60%;
  background-position: center;
}

.grid-item-content {
  padding: 0 20px 20px 20px;
}

.item-title {
  font-size: 24px;
  line-height: 26px;
  font-weight: 700;
  margin-bottom: 18px;
  display: block;
}

.item-category {
  text-transform: uppercase;
  display: block;
  margin-bottom: 20px;
  font-size: 14px;
}

.item-excerpt {
  margin-bottom: 20px;
  display: block;
  font-size: 14px;
}

.grid-item:hover .grid-item-wrapper {
  /*padding: 1% 1%;*/
  /*margin: -1% -1%;*/
  /*border-color: var(--soft-blue);*/
}

@media(max-width: 1333px) {
  .grid-item {
    flex-basis: 33.33%;
  }
}

@media(max-width: 1240px) {
  .grid-item {
    flex-basis: 50%;
  }
}

@media(max-width: 960px) {
  .grid-item {
    flex-basis: 100%;
  }
}

@media(max-width: 620px) {
  .col {
    clear: both;
    float: none;
    margin-left: auto;
    margin-right: auto;
    width: auto !important;
  }
}

@media(max-width: 555px) {
  .grid-item {
    flex-basis: 100%;
  }
}

.case-img-wrap {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.cases-context-menu {
  position: absolute;
  border-radius: 3px;
  box-shadow: 3px 3px 10px 0 rgba(89, 99, 135, 0.2);
  background-color: var(--white);
  padding: 16px;
  z-index: 25;
  transform: translate(-100%, 0);
  width: max-content;
}

.grid-item__selected {
  border-color: var(--reflex-bluet);
}
</style>
