<template>
  <gl-modal
    v-bind="$attrs"
    submit-title="Delete"
    title="Delete Case"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <div class="mb-4">
      Are you sure you want to delete <strong class="break-word">{{ title }}</strong> case?
      This action cannot be undone.
    </div>
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'
import GlInput from '@/components/gl-input'

export default {
  components: {
    GlModal,
    GlInput,
  },
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    }
  },
  methods: {
    onSubmit() {
      this.$emit('remove', this.id)
      this.$emit('input', false)
    },
  },
}
</script>
